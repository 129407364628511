@tailwind base;
@tailwind components;
@tailwind utilities;


::selection {
  color: #121212;
  background-color: #FFEA20;
  outline: none;
}

::-webkit-scrollbar {
  width: 0.8rem;
}

::-webkit-scrollbar-track {
  background-color: #121212;
}

::-webkit-scrollbar-thumb {
  background-color: #454a4d;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #575e62;
}

.lined-paragraph {
  text-align: justify;
  white-space: pre-wrap;
  /* Optional for wrapping long lines */
}


body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  /* font-weight: 500; */
  /* letter-spacing: 0.05rem; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

textarea {
  resize: none;
}

.basic-shadow {
  box-shadow: 2px 2px 15px 0 #b7b7b7;
}

.basic-shadow.dark {
  box-shadow: 2px 2px 15px 0 #000000;
}

.AAnjnfwi3:last-child {
  border-right: 0 !important;
}

.ZGb883h:hover .ZGni29 {
  color: rgb(239 68 68);
}

.Vheb73njd {
  background-repeat: no-repeat !important;
  background-size: 100% 100% !important;
}


.hduieier .a8odn:first-child {
  margin-top: 0;
}

.hduieier .a8odn:last-child {
  margin-bottom: 0;
}

.a {
  fill: url(#g1)
}

.b {
  fill: #fff
}

/* for mobile */
@media (max-width:640px) {
  .Vheb73njd {
    height: 34rem;
    background: linear-gradient(to bottom, rgb(0 0 0 / 80%) 2%, rgb(255 255 255 / 12%) 98%),
      url("https://firebasestorage.googleapis.com/v0/b/shaadowweb-x73h.appspot.com/o/landing-page-banner-mobile-view-img1.png?alt=media&token=5db74f1e-ed4f-4f65-8ead-e874c40894bf");
  }
}

/* for desktop */
@media (min-width:640px) and (max-width:768px) {
  .Vheb73njd {
    height: 22rem;
    background: linear-gradient(80deg, rgb(0 0 0 / 80%) 2%, rgb(255 255 255 / 5%) 98%),
      url("https://firebasestorage.googleapis.com/v0/b/shaadowweb-x73h.appspot.com/o/landing-page-banner-desktop-view-img1.png?alt=media&token=1a0c28a5-7a44-435e-be24-4d9fb9dcd2c8");
  }
}

/* for desktop */
@media (min-width:768px) {
  .Vheb73njd {
    height: 35rem;
    background: linear-gradient(80deg, rgb(0 0 0 / 80%) 2%, rgb(255 255 255 / 5%) 98%),
      url("https://firebasestorage.googleapis.com/v0/b/shaadowweb-x73h.appspot.com/o/landing-page-banner-desktop-view-img1.png?alt=media&token=1a0c28a5-7a44-435e-be24-4d9fb9dcd2c8");
  }
}

.st0 {
  stroke: '#000';
  stroke-width: '1.5';
  stroke-linecap: 'round';
  stroke-linejoin: 'round';
  stroke-miterlimit: '10';
}

.st1 {
  stroke: '#000';
  stroke-width: '1.5';
  stroke-linecap: 'round';
  stroke-linejoin: 'round';
  stroke-miterlimit: '10';
}

.thumbnail {
  --aspect-ratio: 16/9;
}


img {
  pointer-events: none;
}


section {
  margin-top: -0.5px;
}

.special-font {
  font-family: "Protest Revolution", sans-serif;
  font-style: normal;
}


.vy8ggjAWfg .b77x5Ty {
  /* display: none; */
  background-color: rgb(9 9 11);
  ;
}

.vy8ggjAWfg:hover .b77x5Ty {
  display: block;
}

.vy8ggjAWfg .bh8vAwegFY86:hover svg,
.vy8ggjAWfg .bh8vAwegFY86.active svg {
  fill: #ffffff;
}

.vy8ggjAWfg .bh8vAwegFY86:hover span,
.vy8ggjAWfg .bh8vAwegFY86.active span {
  color: #ffffff;
}

.bu93e .n29 {
  transition: all 0.2s ease-in-out;
  letter-spacing: 1px;
}

@media (min-width:1023px) {

  .bu93e .iej2uhHue .BAjd3u3bhue.active {
    position: relative;
  }

  .bu93e .iej2uhHue .BAjd3u3bhue.active::after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px;
    content: '';
    background: #f9f9f9 !important;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }
}

.bu93e .iej2uhHue .BAjd3u3bhue .bu83nVUw:hover,
.bu93e .iej2uhHue .BAjd3u3bhue.active .bu83nVUw {
  color: #f9f9f9 !important;
}

.c6fAe5t {
  background-color: #00000000;
  background-image: linear-gradient(135deg, #ffffff 10%, #0000 0, #0000 50%, #00000000 0, #00000000 60%, #0000 0, #0000);
  background-size: 7px 7px;
}

.b7hHuwjeH72 {
  background: linear-gradient(-110deg, rgb(24 24 27) .06%, rgb(12 12 12));
  box-shadow: inset 0 0 6px hsla(0, 0%, 100%, .1);
}


@media (max-width: 640px) {
  .b83jHuje::before {
    width: 1.2rem !important;
  }

  .b83jHuje::after {
    width: 1.2rem !important;
  }

  .ie93jBuejmm::after {
    top: -30% !important;
  }

  .ie93jBuejmm::before {
    width: 108.2% !important;
  }
}

.ie93jBuejmm::after {
  top: -20%;
  position: absolute;
  left: 50%;
  width: 100.2%;
  transform: translateX(-50%);
  height: 1.5rem;
  content: '';
  border: 1px dashed rgb(55 65 81);
  border-top: 0;
  border-bottom: 0;
}

.ie93jBuejmm::before {
  position: absolute;
  top: -50%;
  left: 50%;
  transform: translateX(-50%);
  width: 105.5%;
  height: 50%;
  content: '';
  border: 1px dashed rgb(55 65 81);
  border-top: 0;
  border-left: 0;
  border-right: 0;
}

.b83jHuje::before {
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  width: 3.25rem;
  height: 50%;
  content: '';
  border: 1px dashed rgb(55 65 81);
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
}

.b83jHuje::after {
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translateX(100%);
  width: 3.25rem;
  height: 50%;
  content: '';
  border: 1px dashed rgb(55 65 81);
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
}


.m92nuehhu3 {
  position: absolute;
  bottom: 100%;
  left: 50%;
  width: 35%;
  transform: translateX(-50%);
  height: 1.8rem;
  border-right: 1px dashed rgb(55 65 81);
  border-left: 1px dashed rgb(55 65 81);
}

.aji3b893::after {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: 100.2%;
  height: 2.5rem;
  content: '';
  border: 1px dashed rgb(55 65 81);
  border-top: 0;
  border-bottom: 0;
}

.aji3b893::before {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: 35%;
  height: 2.5rem;
  content: '';
  border: 1px dashed rgb(55 65 81);
  border-top: 0;
  border-bottom: 0;
}


.bt4mej93NuwjHue {
  position: relative;
  overflow: hidden;
  border: 1px solid rgb(39 39 42);
  color: #121212;
  display: inline-block;
  font-size: 1rem;
  line-height: 1rem;
  padding: 10px 10px 9px;
  text-decoration: none;
  cursor: pointer;
  background: #c6c6c6;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  height: 50px;
  font-weight: 600;
  letter-spacing: 0.5px;
}

@media (max-width: 768px) {
  .bt4mej93NuwjHue {
    font-size: 0.95rem;
    line-height: 0.95rem;
  }
}

.bt4mej93NuwjHue span:first-child {
  position: relative;
  transition: color 600ms cubic-bezier(0.48, 0, 0.12, 1);
  z-index: 10;
}

.bt4mej93NuwjHue span:last-child {
  color: #c6c6c6;
  display: block;
  position: absolute;
  /* bottom: 0; */
  transition: all 500ms cubic-bezier(0.48, 0, 0.12, 1);
  z-index: 100;
  opacity: 0;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  height: 0.5rem;
  width: 100%;
  line-height: 15px;
}

.bt4mej93NuwjHue:after {
  content: "";
  position: absolute;
  bottom: -50%;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(9 9 11);
  transform-origin: bottom center;
  transition: transform 600ms cubic-bezier(0.48, 0, 0.12, 1);
  transform: skewY(9.3deg) scaleY(0);
  z-index: 50;
}

.bt4mej93NuwjHue:hover:after {
  transform-origin: bottom center;
  transform: skewY(9.3deg) scaleY(2);
}

.bt4mej93NuwjHue:hover span:last-child {
  transform: translateX(-50%) translateY(-100%);
  opacity: 1;
  transition: all 900ms cubic-bezier(0.48, 0, 0.12, 1);
}

.qewbue87Gyeu {
  position: absolute;
  width: 9rem;
  height: 9rem;
  background: #27272a40;
  border-radius: 50%;
  animation: animationEffect 3s ease-in-out forwards infinite;
}

.wy83m83Heue {
  position: absolute;
  width: 2.5rem;
  height: 2.5rem;
  background: #27272a40;
  border-radius: 10px;
  animation: animationEffect 3s 2s ease-in-out forwards infinite;
}

.r66ftr5yu {
  position: absolute;
  width: 5rem;
  height: 5rem;
  background: #27272a40;
  border-radius: 20px;
  animation: animationEffect 2s linear infinite;
}

.t8794njie99 {
  position: absolute;
  width: 3rem;
  height: 3rem;
  background-color: #27272a40;
  animation: animationEffect 2s 1.2s linear infinite;
  border-radius: 10px;
}

@keyframes animationEffect {
  0% {
    transform: translate(1rem, 2rem) rotate(45deg);
  }

  50% {
    transform: translate(-2rem, 4rem) rotate(90deg);
  }

  100% {
    transform: translate(1rem, 2rem) rotate(45deg);
  }
}


.balloon-burst {
  /* Burst animation styles */
  animation: burst 1s ease-in-out forwards;
  /* Adjust animation duration and timing function */
}

@keyframes burst {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  50% {
    transform: scale(1.5);
    opacity: 0.5;
  }

  100% {
    transform: scale(0);
    opacity: 0;
  }
}


@keyframes spinSlow {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.QBbu83jejij83 {
  border: 3px solid transparent;
  background:
    linear-gradient(to right, #000, #000),
    linear-gradient(75deg, #00d2ff, #3a7bd5, #f2709c);
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  padding: 5px;
  border-radius: 2rem;
  transition: all 0.8s ease-in-out;
}

@media (max-width: 768px) {
  .QBbu83jejij83 {
    margin: 0 -2.5px;
  }
}



.ZZZby738eheye:last-child a {
  margin-inline-end: 0 !important;
}


.L93b893n span {
  background: linear-gradient(92.05deg, #d2a8ff 12.09%, #f778ba 42.58%, #ff7b72 84.96%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: rgba(0, 0, 0, 0);
}




/* -------------------------------------------------------- blog ------------------------------------- */
/* 5xl */
.blog-content-space h1 {
  font-size: 2.5rem;
  line-height: 1;
  font-weight: 700;
  padding: 0.8rem 0;
  color: rgb(63 63 70);
}

/* 4xl */
.blog-content-space h2 {
  font-size: 2.25rem;
  line-height: 2.5rem;
  font-weight: 700;
  padding: 0.8rem 0;
  color: rgb(63 63 70);
}

/* 3xl */
.blog-content-space h3 {
  font-size: 1.875rem;
  line-height: 2.25rem;
  font-weight: 700;
  padding: 0.8rem 0;
  color: rgb(63 63 70);
}

/* 2xl */
.blog-content-space h4 {
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 700;
  padding: 0.8rem 0;
  color: rgb(63 63 70);
}

/* xl */
.blog-content-space h5 {
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 700;
  padding: 0.8rem 0;
  color: rgb(63 63 70);
}

/* lg */
.blog-content-space h6 {
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 700;
  padding: 0.8rem 0;
  color: rgb(63 63 70);
}

.blog-content-space p {
  font-size: 1rem;
  line-height: 1.5rem;
  color: rgb(63 63 70);
  text-align: justify;
  white-space: pre-wrap;
}

.blog-content-space.dark h1,
.blog-content-space.dark h2,
.blog-content-space.dark h3,
.blog-content-space.dark h4,
.blog-content-space.dark h5,
.blog-content-space.dark h6,
.blog-content-space.dark p,
.blog-content-space.dark ul li,
.blog-content-space.dark ol li {
  color: rgb(161 161 170);
}

.blog-content-space ul,
.blog-content-space ol {
  padding: 0.8rem;
  padding-left: 3rem;
}

.blog-content-space ul li,
.blog-content-space ol li {
  font-size: 1rem;
  line-height: 1.5rem;
  color: rgb(63 63 70);
  padding: 0.15rem 0;
  text-align: justify;
  white-space: pre-wrap;
}

.blog-content-space ul {
  list-style: disc;
}

.blog-content-space ol {
  list-style: decimal;
}

.blog-content-space a {
  font-size: 1rem;
  line-height: 1.5rem;
  color: rgb(220 38 38);
  font-weight: 500;
  text-decoration: underline;
}

.blog-content-space a b,
.blog-content-space b a {
  font-weight: 700;
}



/* .b8i_tags:first-child{margin-left: 0}
.b8i_tags:last-child{margin-right: 0} */

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Protest+Revolution&display=swap');